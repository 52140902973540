import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { environment } from 'src/environments/environment';

const READ_DATE_OBSTACLE_KEY = "READ_DATE_OBSTACLE";
const READ_DATE_KNOWLEDGE_KEY = "READ_DATE_KNOWLEDGE";

@Injectable({
  providedIn: 'root'
})
export class UnreadService {
  
  constructor(private storage:Storage) {

  }

  public addReadDateObstacle(){
    let dateString = this.formateDate(new Date());
    this.storage.set(READ_DATE_OBSTACLE_KEY,dateString);
  }

  public getReadDateObstacle():Promise<any>{
    return this.storage.get(READ_DATE_OBSTACLE_KEY);
  }

  public addReadDateKnowledge(){
    let dateString = this.formateDate(new Date());
    this.storage.set(READ_DATE_KNOWLEDGE_KEY,dateString);
  }

  public getReadDateKnowledge():Promise<any>{
    return this.storage.get(READ_DATE_KNOWLEDGE_KEY);
  }

  private formateDate(date:Date){
    return formatDate(date, environment.DATE_FORMAT, environment.DATE_LOCALE);
  }
}

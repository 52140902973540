import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OneSignal, OSNotificationOpenedResult } from '@ionic-native/onesignal/ngx';
import { Storage } from '@ionic/storage';
import { environment } from 'src/environments/environment';
import { UserDataSingleAnswer } from '../models/userdata.model';
import { UserDataInitialiserService } from './user-data-initialiser.service';
import { UserDataService } from './user-data.service';
@Injectable({
  providedIn: 'root'
})
export class OneSignalService {

  constructor(private oneSignal: OneSignal, private storage: Storage, private router: Router, private userDataInitialiserService: UserDataInitialiserService, private userDataService: UserDataService) { }
  public loadOneSignal() {
    // Optional OneSignal code for iOS to prompt users later
    // Set your iOS Settings
    var iosSettings = {};
    iosSettings["kOSSettingsKeyAutoPrompt"] = false; // will not prompt users when start app 1st time
    iosSettings["kOSSettingsKeyInAppLaunchURL"] = false; // false opens safari with Launch URL
    let that = this;
    // OneSignal Code start:
    // Enable to debug issues.
    //window["plugins"].OneSignal.setLogLevel({logLevel: 4, visualLevel: 4});

    var notificationOpenedCallback = function (jsonData) {
      console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));
      if (jsonData.notification.payload.additionalData != null) {
        console.log("Here we access addtional data");

        /*if(jsonData.notification.payload.additionalData.shoutId != null){
         that.router.navigate(["shout",jsonData.notification.payload.additionalData.shoutId]);
        }*/
        if (jsonData.notification.payload.additionalData.openURL != null) {
          that.router.navigate([jsonData.notification.payload.additionalData.openURL]);
        }
      }
    };

    window["plugins"].OneSignal
      .startInit(environment.ONE_SIGNAL_APP_ID)
      .iOSSettings(iosSettings) // only needed if added Optional OneSignal code for iOS above
      .inFocusDisplaying(window["plugins"].OneSignal.OSInFocusDisplayOption.Notification)
      .handleNotificationOpened(notificationOpenedCallback)
      .endInit();



    this.oneSignal.startInit(environment.ONE_SIGNAL_APP_ID, environment.GOOGLE_PROJECT_ID);

    this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.InAppAlert);

    this.oneSignal.handleNotificationReceived().subscribe(() => {

    });


    this.oneSignal.handleNotificationOpened().subscribe((data: OSNotificationOpenedResult) => {
      let payload = data; // getting id and action in additionalData.
    });
    this.oneSignal.getIds().then(identity => {
      this.storage.set("onesignal_id", identity.userId);
      this.storage.get("TOKEN").then((token: string) => {
        this.userDataInitialiserService.setOneSignalId(identity.userId, token);

      });

    });
  }
}

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiBaseUrl: 'https://hundeschule.maxmayr.com/hundeschule',
  DATE_FORMAT: "yyyy-MM-dd HH:mm:ss",
  DATE_LOCALE: "en-US",
  ONE_SIGNAL_APP_ID: "c6d80243-d017-4916-ba06-ba01f0f26c06",
  GOOGLE_PROJECT_ID: "293296236088"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

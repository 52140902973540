import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserDataAnswer,UserDataSingleAnswer } from '../models/userdata.model';

@Injectable({
  providedIn: 'root'
})
export class UserDataService {
 
  

  constructor(private http:HttpClient,private storage:Storage) { }

  public getUserData(token:string):Observable<UserDataAnswer>{
    let authHeaders = this.getAuthorizationHeader(token);
    return this.http.get<UserDataAnswer>(environment.apiBaseUrl+"/items/user_data",{headers: authHeaders});
  }
  initaliseUserData(token: string):Observable<UserDataSingleAnswer> {
    let authHeaders = this.getAuthorizationHeader(token);
    return this.http.post<UserDataSingleAnswer>(environment.apiBaseUrl+"/items/user_data",{},{headers: authHeaders});
  }
  initaliseUserDataWithOneSignalId(oneSignalId:string, token: string):Observable<UserDataSingleAnswer> {
    let authHeaders = this.getAuthorizationHeader(token);
    let postData = {
      "onesignal_id": oneSignalId
    }
    return this.http.post<UserDataSingleAnswer>(environment.apiBaseUrl+"/items/user_data",postData,{headers: authHeaders});
  }
  updateUserData(id:number,hasNotificationObstacle:boolean,hasNotificationKnowledge:boolean,token: string):Observable<UserDataSingleAnswer>{
    let authHeaders = this.getAuthorizationHeader(token);
    let postData = {
      "push_obstacle": hasNotificationObstacle,
      "push_knowledge": hasNotificationKnowledge
    }
    return this.http.patch<UserDataSingleAnswer>(environment.apiBaseUrl+"/items/user_data/"+id,postData,{headers: authHeaders});
  }
  updateUserDataOneSignal(id:number,oneSignalId: string, token: string) {
    let authHeaders = this.getAuthorizationHeader(token);
    let postData = {
      "onesignal_id": oneSignalId
    }
    return this.http.patch<UserDataSingleAnswer>(environment.apiBaseUrl+"/items/user_data/"+id,postData,{headers: authHeaders});
  }
  private getAuthorizationHeader(token): HttpHeaders {
    return new HttpHeaders({
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    });
  }
}

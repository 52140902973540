import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LocationAnswer, Obstacle, ObstacleAnswer,ObstacleCommentAnswer,ObstacleSingleAnswer } from '../models/obstacle.model';
import { environment } from '../../environments/environment';
import { Storage } from '@ionic/storage';
import { formatDate } from '@angular/common';
@Injectable({
  providedIn: 'root'
})
export class ObstacleService {
  

  constructor(private http:HttpClient,private storage:Storage) { }

  public getObstacles(token:string):Observable<ObstacleAnswer>{
    let authHeaders = this.getAuthorizationHeader(token);
    return this.http.get<ObstacleAnswer>(environment.apiBaseUrl+"/items/obstacle?fields=*,images.directus_files_id.*",{headers: authHeaders});
  }

  public getNewObstacles(token:string):Observable<ObstacleAnswer>{
    let authHeaders = this.getAuthorizationHeader(token);
    let date = new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 183);//183 ca 6 months
    let dateString = formatDate(date, environment.DATE_FORMAT, environment.DATE_LOCALE);
    return this.http.get<ObstacleAnswer>(environment.apiBaseUrl+"/items/obstacle?fields=*,images.directus_files_id.*&filter[created_on][gt]="+dateString,{headers: authHeaders});
  }

  public searchObstacles(search:string,mainCategory:string,subCategory:string,token:string):Observable<ObstacleAnswer>{
    let authHeaders = this.getAuthorizationHeader(token);
    let url = environment.apiBaseUrl+"/items/obstacle?fields=*,images.directus_files_id.*&q="+search;
    if(mainCategory!=null){
      url += "&filter[main_category][eq]="+mainCategory;
    }
    if(subCategory!=null){
      url += "&filter[sub_category][eq]="+subCategory;
    }
    return this.http.get<ObstacleAnswer>(url,{headers: authHeaders});
  }

  public getObstacle(id:number,token:string):Observable<ObstacleSingleAnswer>{
    let authHeaders = this.getAuthorizationHeader(token);
    return this.http.get<ObstacleSingleAnswer>(environment.apiBaseUrl+"/items/obstacle/"+id+"?fields=*,images.directus_files_id.*",{headers: authHeaders});
  }
  public countObstacles(dateString:string,token:string):Observable<ObstacleAnswer>{
    let authHeaders = this.getAuthorizationHeader(token);
    return this.http.get<ObstacleAnswer>(environment.apiBaseUrl+"/items/obstacle"+"?fields=id&limit=1&meta=filter_count&filter[created_on][gt]="+dateString,{headers: authHeaders});
  }
  createObstacle(obstacle:Obstacle,token:string) {
    let authHeaders = this.getAuthorizationHeader(token);
    return this.http.post<ObstacleSingleAnswer>(environment.apiBaseUrl+"/items/obstacle",obstacle,{headers: authHeaders});
  }
  public getComments(obstacleId:number,token:string):Observable<ObstacleCommentAnswer>{
    let authHeaders = this.getAuthorizationHeader(token);
    return this.http.get<ObstacleCommentAnswer>(environment.apiBaseUrl+"/items/comments?fields=*,owner.*&sort=-id&filter[obstacle][eq]="+obstacleId,{headers: authHeaders});
  }

  public getLocations(token:string):Observable<LocationAnswer>{
    let authHeaders = this.getAuthorizationHeader(token);
    return this.http.get<LocationAnswer>(environment.apiBaseUrl+"/items/location?fields=*",{headers: authHeaders});
  }
  createComment(comment:string,obstacleId:number,token:string) {
    let authHeaders = this.getAuthorizationHeader(token);
    let postData = {
      "comment":comment,
      "obstacle": obstacleId
    }
    return this.http.post<ObstacleCommentAnswer>(environment.apiBaseUrl+"/items/comments",postData,{headers: authHeaders});
  }

  createReport(obstacleId:number,description:string,token:string) {
    let authHeaders = this.getAuthorizationHeader(token);
    let postData = {
      "obstacle":obstacleId,
      "description": description
    }
    return this.http.post<any>(environment.apiBaseUrl+"/items/report",postData,{headers: authHeaders});
  }


   private getAuthorizationHeader(token): HttpHeaders {
    return new HttpHeaders({
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    });
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'distance'
})
export class DistancePipe implements PipeTransform {

  transform(distance: number, ...args: any[]): string {
    if(isNaN(distance)){
      return "Standort nicht freigegeben";
    }
    if(distance<1000){
      return "Weniger als ein Kilometer entfernt";
    }
    let km = distance/1000;
    return km.toFixed(2) + " km entfernt";
  }

}

import { Component, OnInit } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AuthService } from './services/auth.service';
import { distinctUntilChanged } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ObstacleService } from './services/obstacle.service';
import { UnreadService } from './services/unread.service';
import { Storage } from '@ionic/storage';
import { KnowledgeService } from './services/knowledge.service';
import { ObstacleAnswer } from './models/obstacle.model';
import { KnowledgeAnswer } from './models/knowledge.model';
import { OneSignalService } from './services/one-signal.service';
import { UserDataService } from 'src/app/services/user-data.service';
import { UserDataAnswer } from './models/userdata.model';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  public selectedIndex = 0;
  public unReadObstacles = 0;
  public unreadKnowledge = 0;
  public appPages = [
    {
      id: 1,
      title: 'Hindernisse',
      url: '/obstacles',
      icon: 'paw'
    }, {
      id: 2,
      title: 'Neuste Hindernisse',
      url: '/new-obstacles',
      icon: 'paw'
    }, {
      id: 3,
      title: 'Hindernisse hinzufügen',
      url: '/add-obstacle',
      icon: 'add'
    }, {
      id: 4,
      title: 'Wissensdatenbank',
      url: '/knowledge',
      icon: 'bulb'
    }, {
      id: 5,
      title: 'Wissen hinzufügen',
      url: '/add-knowledge',
      icon: 'add'
    },
    {
      id: 6,
      title: 'Dienstpläne',
      url: '/schedule',
      icon: 'time'
    }, {
      id: 7,
      title: 'Einstellungen',
      url: '/settings',
      icon: 'settings'
    }, {
      id: 8,
      title: 'Logout',
      url: '/logout',
      icon: 'log-out'
    },
  ];
  isAuthenticated: boolean;
  isLoading: boolean;
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private authService: AuthService,
    private router: Router,
    private obstacleService: ObstacleService,
    private unreadService: UnreadService,
    private storage: Storage,
    private knowledgeService: KnowledgeService,
    private oneSignalService:OneSignalService,
    private userDataService:UserDataService
  ) {
    this.initializeApp();
    this.isAuthenticated = false;
    this.isLoading = true;

    authService.isAuthenticated.pipe(
      distinctUntilChanged(),
    ).subscribe((status: boolean) => {
      if (status) {
        this.countNewObstacles();
        this.countNewKnowledge();
        this.router.navigate(['/']);
        if(this.platform.is("capacitor")){
          this.oneSignalService.loadOneSignal();
        }
      } else {
        this.router.navigate(['/auth']);
      }
    });
  }


  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
    
  }

  private countNewObstacles() {
    this.unreadService.getReadDateObstacle().then((date: string) => {
      this.storage.get("TOKEN").then((token: string) => {
        this.obstacleService.countObstacles(date, token).subscribe((data: ObstacleAnswer) => {
          this.unReadObstacles = data.meta.filter_count;
        })
      });
    });
  }
  private countNewKnowledge() {
    this.unreadService.getReadDateKnowledge().then((date: string) => {
      this.storage.get("TOKEN").then((token: string) => {
        this.knowledgeService.countKnowledge(date, token).subscribe((data: KnowledgeAnswer) => {
          this.unreadKnowledge = data.meta.filter_count;
        })
      });
    });
  }
  public resetUnread(page: any) {
    if (page.id == 2) {
      this.unReadObstacles = 0;
    } else if (page.id == 4) {
      this.unreadKnowledge = 0;
    }
  }

  ngOnInit() {
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { UserDataAnswer, UserDataSingleAnswer } from '../models/userdata.model';

import { UserDataService } from './user-data.service';

@Injectable({
  providedIn: 'root'
})
export class UserDataInitialiserService {

  constructor(private userDataService: UserDataService) { }


  public initaliseUserData(token: string): Observable<UserDataSingleAnswer> {
    return this.userDataService.initaliseUserData(token);
  }

  public setOneSignalId(oneSignalId:string,token:string){
    this.userDataService.getUserData(token).subscribe((data:UserDataAnswer)=>{
      let length = data.data.length;
      if(length==1){
        this.updateUserDataOneSignal(data.data[0].id,oneSignalId,token);
      }
      if(length==0){
        this.userDataService.initaliseUserDataWithOneSignalId(oneSignalId,token).subscribe((data:UserDataSingleAnswer)=>{
          console.log(data);
          this.updateUserDataOneSignal(data.data[0].id,oneSignalId,token);
        },error=>{
          //we do error handling
        });
      }
    })
    
  }
  private updateUserDataOneSignal(id,oneSignalId,token){
    this.userDataService.updateUserDataOneSignal(id,oneSignalId,token).subscribe(data=>{
      //FIRST INITIALISE
    }, error=>{
      //error Handling
    });
  }

}

import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'obstacles',
    pathMatch: 'full'
  },
  {
    path: 'obstacles',
    loadChildren: () => import('./pages/obstacles/obstacles.module').then( m => m.ObstaclesPageModule)
  },
  {
    path: 'obstacles/:id',
    loadChildren: () => import('./pages/obstacle/obstacle.module').then( m => m.ObstaclePageModule)
  },
  {
    path: 'auth',
    loadChildren: () => import('./pages/auth/auth.module').then( m => m.AuthPageModule)
  },
  {
    path: 'add-obstacle',
    loadChildren: () => import('./pages/add-obstacle/add-obstacle.module').then( m => m.AddObstaclePageModule)
  },
  {
    path: 'knowledge',
    loadChildren: () => import('./pages/knowledge/knowledge.module').then( m => m.KnowledgePageModule)
  },
  {
    path: 'add-knowledge',
    loadChildren: () => import('./pages/add-knowledge/add-knowledge.module').then( m => m.AddKnowledgePageModule)
  },
  {
    path: 'knowledge/:id',
    loadChildren: () => import('./pages/one-knowledge/one-knowledge.module').then( m => m.OneKnowledgePageModule)
  },
  {
    path: 'logout',
    loadChildren: () => import('./pages/logout/logout.module').then( m => m.LogoutPageModule)
  },
  {
    path: 'schedule',
    loadChildren: () => import('./pages/schedule/schedule.module').then( m => m.SchedulePageModule)
  },
  {
    path: 'new-obstacles',
    loadChildren: () => import('./pages/new-obstacles/new-obstacles.module').then( m => m.NewObstaclesPageModule)
  },
  {
    path: 'report-obstacle/:id',
    loadChildren: () => import('./pages/report-obstacle/report-obstacle.module').then( m => m.ReportObstaclePageModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./pages/settings/settings.module').then( m => m.SettingsPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Storage } from '@ionic/storage';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { KnowledgeAnswer,KnowledgeSingleAnswer,KnowledgeCommentAnswer, Knowledge } from '../models/knowledge.model';
import { FieldAnswer } from '../models/fields.model';
@Injectable({
  providedIn: 'root'
})
export class KnowledgeService {

  constructor(private http:HttpClient,private storage:Storage) { }
  public getKnowledge(search:string,token:string):Observable<KnowledgeAnswer>{
    let authHeaders = this.getAuthorizationHeader(token);
    return this.http.get<KnowledgeAnswer>(environment.apiBaseUrl+"/items/knowledge?q="+search+"&fields=*,files.directus_files_id.*&sort=-created_on",{headers: authHeaders});
  }

  public getOneKnowledge(id:number,token:string):Observable<KnowledgeSingleAnswer>{
    let authHeaders = this.getAuthorizationHeader(token);
    return this.http.get<KnowledgeSingleAnswer>(environment.apiBaseUrl+"/items/knowledge/"+id+"?fields=*,files.directus_files_id.*",{headers: authHeaders});
  }
  public countKnowledge(dateString:string,token:string):Observable<KnowledgeAnswer>{
    let authHeaders = this.getAuthorizationHeader(token);
    return this.http.get<KnowledgeAnswer>(environment.apiBaseUrl+"/items/knowledge"+"?fields=id&limit=0&meta=filter_count&filter[created_on][gt]="+dateString,{headers: authHeaders});
  }
  createKnowledge(knowledge:Knowledge,token:string) {
    let authHeaders = this.getAuthorizationHeader(token);
    return this.http.post<KnowledgeSingleAnswer>(environment.apiBaseUrl+"/items/knowledge",knowledge,{headers: authHeaders});
  }
  public getComments(knowledgeId:number,token:string):Observable<KnowledgeCommentAnswer>{
    let authHeaders = this.getAuthorizationHeader(token);
    return this.http.get<KnowledgeCommentAnswer>(environment.apiBaseUrl+"/items/konwledge_comments?fields=*,owner.*&sort=-id&filter[knowledge][eq]="+knowledgeId,{headers: authHeaders});
  }

  public getFields(token:string):Observable<FieldAnswer>{
    let authHeaders = this.getAuthorizationHeader(token);
    return this.http.get<FieldAnswer>(environment.apiBaseUrl+"/fields/knowledge",{headers: authHeaders});
  }
  createComment(comment:string,knowledgeId:number,token:string) {
    let authHeaders = this.getAuthorizationHeader(token);
    let postData = {
      "comment":comment,
      "knowledge": knowledgeId
    }
    return this.http.post<KnowledgeCommentAnswer>(environment.apiBaseUrl+"/items/konwledge_comments",postData,{headers: authHeaders});
  }
  private getAuthorizationHeader(token): HttpHeaders {    
    return new HttpHeaders({
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    });
  }
}

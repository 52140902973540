import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { User } from '../models/user.model';
import { ILogin, ILoginAnswer } from '../models/login.model';
import { environment } from '../../environments/environment';
import { Storage } from '@ionic/storage';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public isAuthenticated = new BehaviorSubject<boolean>(false);
  constructor(private router: Router,
    private http: HttpClient, private storage: Storage) {
    this.storage.get('TOKEN').then((token: string) => {
      this.checkAuthenticated(token);
    });
  }
  checkAuthenticated(token: string): string {
    if (token && !this.tokenExpired(token)) {
      this.isAuthenticated.next(true);
    } else {
      this.isAuthenticated.next(false);
    }
    return token;
  }

  login(login: ILogin) {
    var headers = new HttpHeaders();
    headers.set("Accept", 'application/json');
    headers.set('Content-Type', 'application/json');
    let url = environment.apiBaseUrl + "/auth/authenticate";
    return this.http.post(url, login, { headers: headers });
  }

  async logout() {
    this.isAuthenticated.next(false);
    this.storage.clear();
    this.router.navigate(["/auth"])
  }

  getUser(): Promise<User> {
    //return this.http.get<User>('/users/me').toPromise();
    //TODO: implement
    return null;
  }

  isLoggedIn(): boolean {
    return this.isAuthenticated.value;
  }

  private tokenExpired(token: string): boolean {
    const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
    return (Math.floor((new Date).getTime() / 1000)) >= expiry;
  }
}

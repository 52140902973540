import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'maxText'
})
export class MaxTextPipe implements PipeTransform {

  transform(text: string, ...args: number[]): string {
    let maxText = 250;
    if(args.length==1){
      maxText = args[0];
    }
    return text.substring(0,maxText)+"...";
  }

}
